<template>
    <div class="d-flex section-card">
        <v-card class="rounded-12 section-card selected-component" flat outlined>
            <v-card-text class="px-6">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                        <v-col cols="12" xs="12" sm="7" md="7">
                            <v-text-field
                                label="Label"
                                v-model="component.component_label"
                                :rules="[(v) => !!v || 'Label is required']"
                                flat
                                outlined
                                dense
                                @input="generateFieldName()"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="5">
                            <v-select
                                :items="inputTypes"
                                v-model="component.component_type"
                                :rules="[(v) => !!v || 'Text field is required']"
                                label="Field Type"
                                @change="changeType()"
                                flat
                                outlined
                                hide-details
                                dense
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="pb-2">
                        <v-col
                            cols="12"
                            xs="12"
                            sm="7"
                            md="7"
                            v-if="
                                component.component_type !== 'LABEL' &&
                                component.component_type !== 'SPACER' &&
                                component.component_type !== 'PAYMENT' &&
                                component.component_type !== 'TEXT_EDITOR' &&
                                component.component_type !== 'APPLICATION_STATUS' &&
                                component.component_type !== 'COURSES' &&
                                component.component_type !== 'DOCUMENT' &&
                                component.component_type !== 'FILE_UPLOAD' &&
                                component.component_type !== 'BUTTON'
                            "
                        >
                            <v-text-field
                                label="Placeholder"
                                v-model="component.component_placeholder"
                                flat
                                outlined
                                hide-details
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="5" md="5">
                            <v-select
                                :items="gridSizes"
                                v-model="component.grid_size"
                                label="Grid Size"
                                flat
                                outlined
                                hide-details
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="4" xs="4" sm="4" md="4">
                            <v-text-field
                                label="File Size"
                                v-if="
                                    component.component_type === 'DOCUMENT' ||
                                    component.component_type === 'FILE_UPLOAD'
                                "
                                @input="generateFileSize()"
                                :rules="[(v) => v.length > 0 || 'File size is required']"
                                v-model="component.file_size"
                                flat
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="3" md="3">
                            <v-select
                                :items="fileSizeUnits"
                                v-model="component.file_size_unit"
                                v-if="
                                    component.component_type === 'DOCUMENT' ||
                                    component.component_type === 'FILE_UPLOAD'
                                "
                                @change="generateFileSizeUnit()"
                                :rules="[(v) => !!v || 'File size unit is required']"
                                flat
                                outlined
                                hide-details
                                dense
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            xs="12"
                            sm="7"
                            md="7"
                            v-if="
                                component.component_type === 'FILE_UPLOAD' ||
                                component.component_type === 'DOCUMENT'
                            "
                        >
                            <v-select
                                v-model="component.allowed_file_types"
                                :items="fileTypes"
                                flat
                                outlined
                                dense
                                :rules="[(v) => v.length > 0 || 'File extension is required']"
                                label="Select file extension"
                                multiple
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            xs="12"
                            sm="12"
                            md="12"
                            v-if="component.component_type === 'DOCUMENT'"
                        >
                            <v-select
                                v-model="component.document_type"
                                :items="documentTypes"
                                flat
                                outlined
                                dense
                                item-text="name"
                                item-value="value"
                                :rules="[(v) => !!v || 'Type is required']"
                                label="Type"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <TextField
                        v-if="component.component_type === 'TEXT_FIELD'"
                        v-on:update-values="updateComponentValues"
                        :defaultValue="component.component_default_value"
                        :isShowLabel="component.is_show_label"
                        :isHidden="component.is_hidden"
                    />
                    <TextArea
                        v-if="component.component_type === 'TEXT_AREA'"
                        v-on:update-values="updateComponentValues"
                        :defaultValue="component.component_default_value"
                        :isShowLabel="component.is_show_label"
                        :isHidden="component.is_hidden"
                    />

                    <DropDown
                        v-if="component.component_type === 'DROP_DOWN'"
                        v-on:update-values="updateComponentValues"
                        :componentDataSet="component.component_data_set"
                        :isShowLabel="component.is_show_label"
                    />

                    <CheckBox
                        v-if="component.component_type === 'CHECK_BOX'"
                        v-on:update-values="updateComponentValues"
                        :componentDataSet="component.component_data_set"
                        :isShowLabel="component.is_show_label"
                        :defaultValue="component.component_default_value"
                    />

                    <Radio
                        v-if="component.component_type === 'RADIO'"
                        v-on:update-values="updateComponentValues"
                        :componentDataSet="component.component_data_set"
                        :isShowLabel="component.is_show_label"
                    />

                    <Date
                        v-if="component.component_type === 'DATE'"
                        v-on:update-values="updateComponentValues"
                        :defaultValue="component.component_default_value"
                        :isShowLabel="component.is_show_label"
                        :isHidden="component.is_hidden"
                    />

                    <Time
                        v-if="component.component_type === 'TIME'"
                        v-on:update-values="updateComponentValues"
                        :defaultValue="component.component_default_value"
                        :isShowLabel="component.is_show_label"
                        :isHidden="component.is_hidden"
                    />

                    <TextEditor
                        v-if="component.component_type === 'TEXT_EDITOR'"
                        v-on:update-values="updateComponentValues"
                        :defaultValue="component.component_default_value"
                        :isShowLabel="component.is_show_label"
                        :isHidden="component.is_hidden"
                    />

                    <Button
                        v-if="component.component_type === 'BUTTON'"
                        v-on:update-values="updateComponentValues"
                        :defaultValue="component.component_default_value"
                        :isShowLabel="component.is_show_label"
                        :isHidden="component.is_hidden"
                        :isOpenOnTab="component.is_open_on_tab"
                        :isButtonColor="component.button_color"
                    />

                    <FileUpload
                        v-if="
                            component.component_type === 'DOCUMENT' ||
                            component.component_type === 'FILE_UPLOAD'
                        "
                        v-on:update-values="updateComponentValues"
                        :isShowLabel="component.is_show_label"
                        :isHideComponent="component.is_hide_component"
                    />

                    <FromDatabase
                        v-if="component.component_type === 'DATABASE'"
                        v-on:update-values="updateComponentValues"
                        :isShowLabel="component.is_show_label"
                        :dbField="component.db_field"
                        :frontEndInsertion="component.is_frontend_insertion"
                    />
                    <Competitor
                        v-if="component.component_type === 'COMPETITOR'"
                        v-on:update-values="updateComponentValues"
                        :isShowLabel="component.is_show_label"
                        :dbField="component.db_field"
                        :frontEndInsertion="component.is_frontend_insertion"
                    />

                    <CrmDropDown
                        v-if="component.component_type === 'CRM_DROP_DOWN'"
                        v-on:update-values="updateComponentValues"
                        :isShowLabel="component.is_show_label"
                        :crmDropdown="component.crm_drop_down"
                    />
                    <Courses
                        v-if="component.component_type === 'COURSES'"
                        v-on:update-values="updateComponentValues"
                        :isShowLabel="component.is_show_label"
                        :courseCondition="
                            component.courses_conditions ? component.courses_conditions : []
                        "
                    />

                    <v-row
                        v-bind:class="
                            component.component_type !== 'TEXT_EDITOR' &&
                            component.component_type !== 'LABEL' &&
                            component.component_type !== 'SPACER' &&
                            component.component_type !== 'APPLICATION_STATUS' &&
                            component.component_type !== 'COURSES'
                                ? 'entity-section pt-2'
                                : ''
                        "
                        v-if="this.isAdmin"
                    >
                        <v-col
                            cols="12"
                            xs="12"
                            sm="5"
                            md="5"
                            v-if="
                                component.component_type !== 'TEXT_EDITOR' &&
                                component.component_type !== 'LABEL' &&
                                component.component_type !== 'SPACER' &&
                                component.component_type !== 'PAYMENT' &&
                                component.component_type !== 'DOCUMENT' &&
                                component.component_type !== 'APPLICATION_STATUS' &&
                                component.component_type !== 'COURSES'
                            "
                        >
                            <v-select
                                :items="entityTypes"
                                label="Entity Type"
                                v-model="component.component_entity"
                                :rules="[(v) => !!v || 'Entity Type is required']"
                                flat
                                item-text="name"
                                item-value="entity_type"
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            xs="12"
                            sm="7"
                            md="7"
                            v-if="
                                component.component_type !== 'TEXT_EDITOR' &&
                                component.component_type !== 'LABEL' &&
                                component.component_type !== 'SPACER' &&
                                component.component_type !== 'APPLICATION_STATUS' &&
                                component.component_type !== 'COURSES'
                            "
                        >
                            <v-text-field
                                label="CRM Field"
                                :rules="[(v) => !!v || 'CRM field is required']"
                                v-model="component.component_crm_field"
                                flat
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" xs="12" sm="8" md="8" class="d-flex">
                            <v-switch
                                v-if="
                                    component.component_type !== 'TEXT_EDITOR' &&
                                    component.component_type !== 'LABEL' &&
                                    component.component_type !== 'SPACER' &&
                                    component.component_type !== 'PAYMENT' &&
                                    component.component_type !== 'APPLICATION_STATUS' &&
                                    component.component_type !== 'BUTTON'
                                "
                                v-model="component.advance_validation_rules.is_required"
                                label="Required"
                                hide-details
                                class="mr-4 mt-2"
                                dense
                            ></v-switch>
                            <v-switch
                                label="Admin Only"
                                v-model="component.is_admin_only"
                                hide-details
                                class="mr-4 mt-2"
                                dense
                            ></v-switch>
                            <v-switch
                                v-if="
                                    component.component_type !== 'TEXT_EDITOR' &&
                                    component.component_type !== 'LABEL' &&
                                    component.component_type !== 'SPACER' &&
                                    component.component_type !== 'PAYMENT' &&
                                    component.component_type !== 'APPLICATION_STATUS'
                                "
                                label="Disable"
                                v-model="component.is_disable"
                                hide-details
                                class="mr-4 mt-2"
                                dense
                            ></v-switch>
                        </v-col>
                        <v-col cols="12" xs="12" sm="4" md="4" align-self="end">
                            <v-checkbox
                                v-if="this.isAdmin"
                                label="Advanced Condition"
                                v-model="component.is_advance_condition"
                                @change="changeAdvanceCondition"
                                outlined
                                hide-details
                                dense
                                class="mt-2"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row v-if="component.is_advance_condition && this.isAdmin">
                        <v-col cols="12">
                            <v-tabs v-model="tab">
                                <v-tab :key="1" :disabled="component.component_type === 'BUTTON'">
                                    Validation</v-tab
                                >
                                <v-tab :key="2"> Enability</v-tab>
                                <v-tab :key="3"> Visibility</v-tab>
                                <v-tab :key="4"> Edit Condition</v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <v-tab-item :key="1">
                                    <v-row class="mt-1">
                                        <v-col cols="12" xs="12" sm="4">
                                            <v-select
                                                :items="types"
                                                label="Field Type"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .validation_rules.field_type
                                                "
                                                :disabled="component.component_type === 'BUTTON'"
                                                @change="changeFieldType"
                                                flat
                                                outlined
                                                hide-details
                                                dense
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="4">
                                            <v-select
                                                :items="fieldLogic"
                                                label="Action"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .validation_rules.type
                                                "
                                                :disabled="
                                                    component.advance_validation_rules
                                                        .validation_rules.field_type === 'email' &&
                                                    component.component_type === 'TEXT_FIELD'
                                                "
                                                flat
                                                outlined
                                                hide-details
                                                dense
                                                @change="changeFieldLogic"
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            xs="10"
                                            sm="3"
                                            v-if="
                                                component.advance_validation_rules.validation_rules
                                                    .field_type != 'date'
                                            "
                                        >
                                            <v-text-field
                                                label="value"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .validation_rules.value
                                                "
                                                flat
                                                :disabled="
                                                    (component.advance_validation_rules
                                                        .validation_rules.field_type === 'email' &&
                                                        component.component_type ===
                                                            'TEXT_FIELD') ||
                                                    component.advance_validation_rules
                                                        .validation_rules.type === null
                                                "
                                                outlined
                                                :rules="getValidation('Value')"
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            xs="10"
                                            sm="3"
                                            v-if="
                                                component.advance_validation_rules.validation_rules
                                                    .field_type == 'date'
                                            "
                                        >
                                            <v-menu
                                                v-model="dateActionPicker"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        label="value"
                                                        :value="formatDate"
                                                        flat
                                                        :disabled="
                                                            component.advance_validation_rules
                                                                .validation_rules.type === null ||
                                                            component.advance_validation_rules
                                                                .validation_rules.type ===
                                                                'futureDate' ||
                                                            component.advance_validation_rules
                                                                .validation_rules.type ===
                                                                'pastDate'
                                                        "
                                                        outlined
                                                        :rules="getValidation('Value')"
                                                        dense
                                                        prepend-inner-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="
                                                        component.advance_validation_rules
                                                            .validation_rules.value
                                                    "
                                                    @input="handleCloseDatePicker"
                                                    :range="
                                                        component.advance_validation_rules
                                                            .validation_rules.type == 'dateBetween'
                                                    "
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" xs="2" sm="1" class="pl-0">
                                            <v-btn
                                                text
                                                icon
                                                color="darkGray"
                                                @click="resetValidation('type')"
                                            >
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item :key="2">
                                    <v-row
                                        class="d-sm-flex d-xs-block justify-space-between justify-center align-center text-center mt-3 pl-3"
                                        v-for="(option, index) in component.advance_validation_rules
                                            .enability_rules"
                                        :key="index"
                                    >
                                        <v-col cols="12" xs="12" sm="3" md="3" class="ma-0 pa-0">
                                            <v-text-field
                                                outlined
                                                label="CRM Field"
                                                dense
                                                :rules="getValidationRules('CRM field')"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .enability_rules[index].parent_field
                                                "
                                                class="ml-2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3" md="3" class="ma-0 pa-0">
                                            <v-text-field
                                                dense
                                                flat
                                                outlined
                                                label="Value"
                                                :rules="getValidationRules('Value')"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .enability_rules[index].value
                                                "
                                                class="ml-2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="5" sm="3" md="3" class="ma-0 pa-0">
                                            <v-select
                                                dense
                                                flat
                                                outlined
                                                :items="enableActions"
                                                label="Action"
                                                @change="changeAction('enability_rules', index)"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .enability_rules[index].action
                                                "
                                                class="ml-2"
                                                :rules="getValidationRules('Action')"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="5" sm="2" md="2" class="ma-0 pa-0">
                                            <v-select
                                                dense
                                                flat
                                                outlined
                                                :items="operators"
                                                label="Operator"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .enability_rules[index].operator
                                                "
                                                :rules="getValidationRules('Operator')"
                                                class="ml-2"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="2" sm="1" md="1" class="ma-0 pa-0">
                                            <v-btn
                                                text
                                                icon
                                                color="darkGray"
                                                class="mb-md-5"
                                                @click="resetValidation('enability', index)"
                                            >
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        small
                                        class="mt-3 mb-3"
                                        color="secondary"
                                        elevation="0"
                                        @click="addCondition('enability')"
                                    >
                                        Add Conditions
                                    </v-btn>
                                </v-tab-item>
                                <v-tab-item :key="3">
                                    <v-row
                                        class="d-sm-flex d-xs-block justify-space-between justify-center align-center text-center mt-3 pl-3"
                                        v-for="(option, index) in component.advance_validation_rules
                                            .visibility_rules"
                                        :key="index"
                                    >
                                        <v-col cols="12" xs="12" sm="3" class="ma-0 pa-0">
                                            <v-text-field
                                                outlined
                                                label="CRM Field"
                                                dense
                                                v-model="
                                                    component.advance_validation_rules
                                                        .visibility_rules[index].parent_field
                                                "
                                                class="ml-2"
                                                :rules="getValidationRules('CRM field')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3" class="ma-0 pa-0">
                                            <v-text-field
                                                dense
                                                flat
                                                outlined
                                                label="Value"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .visibility_rules[index].value
                                                "
                                                class="ml-2"
                                                :rules="getValidationRules('Value')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="5" sm="3" class="ma-0 pa-0">
                                            <v-select
                                                dense
                                                flat
                                                outlined
                                                :items="visibleActions"
                                                label="Action"
                                                @change="changeAction('visibility_rules', index)"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .visibility_rules[index].action
                                                "
                                                :rules="getValidationRules('Action')"
                                                class="ml-2"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="2" class="ma-0 pa-0">
                                            <v-select
                                                dense
                                                flat
                                                outlined
                                                :items="operators"
                                                label="Operator"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .visibility_rules[index].operator
                                                "
                                                :rules="getValidationRules('Operator')"
                                                class="ml-2"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="1" class="ma-0 pa-0">
                                            <v-btn
                                                text
                                                icon
                                                color="darkGray"
                                                class="mb-md-5"
                                                @click="resetValidation('visibility', index)"
                                            >
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        small
                                        class="mt-3 mb-3"
                                        color="secondary"
                                        elevation="0"
                                        @click="addCondition('visibility')"
                                    >
                                        Add Conditions
                                    </v-btn>
                                </v-tab-item>

                                <v-tab-item :key="4">
                                    <v-row
                                        class="d-sm-flex d-xs-block justify-space-between justify-center align-center text-center mt-3 pl-3"
                                        v-for="(option, index) in component.advance_validation_rules
                                            .edit_rules"
                                        :key="index"
                                    >
                                        <v-col cols="12" xs="12" sm="3" class="ma-0 pa-0">
                                            <v-text-field
                                                outlined
                                                label="CRM Field"
                                                dense
                                                v-model="
                                                    component.advance_validation_rules.edit_rules[
                                                        index
                                                    ].parent_field
                                                "
                                                class="ml-2"
                                                :rules="getValidationRules('CRM field')"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="3" class="ma-0 pa-0">
                                            <v-text-field
                                                dense
                                                flat
                                                outlined
                                                label="Value"
                                                v-model="
                                                    component.advance_validation_rules.edit_rules[
                                                        index
                                                    ].value
                                                "
                                                class="ml-2"
                                                :rules="getValidationRules('Value')"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" xs="5" sm="3" class="ma-0 pa-0">
                                            <v-select
                                                dense
                                                flat
                                                outlined
                                                :items="enableActions"
                                                label="Action"
                                                @change="changeAction('edit_rules', index)"
                                                v-model="
                                                    component.advance_validation_rules.edit_rules[
                                                        index
                                                    ].action
                                                "
                                                :rules="getValidationRules('Action')"
                                                class="ml-2"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="12" xs="5" sm="2" class="ma-0 pa-0">
                                            <v-select
                                                dense
                                                flat
                                                outlined
                                                :items="operators"
                                                label="Operator"
                                                v-model="
                                                    component.advance_validation_rules.edit_rules[
                                                        index
                                                    ].operator
                                                "
                                                :rules="getValidationRules('Operator')"
                                                class="ml-2"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="12" xs="2" sm="1" class="ma-0 pa-0">
                                            <v-btn
                                                text
                                                icon
                                                color="darkGray"
                                                class="mb-md-5"
                                                @click="resetValidation('edit_rules', index)"
                                            >
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        small
                                        class="mt-3 mb-3"
                                        color="secondary"
                                        elevation="0"
                                        @click="addCondition('edit_rules')"
                                    >
                                        Add Conditions
                                    </v-btn>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <div class="floating-icons">
            <v-card class="rounded-12" flat>
                <v-card-text class="pa-1 d-flex flex-column">
                    <v-btn text icon color="darkGray" @click="addComponent()">
                        <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                    <v-btn v-show="false" text icon color="darkGray" @click="updateComponent()">
                        <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                    <v-btn text icon color="darkGray" @click="deleteComponent()">
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                    <!--<v-btn text icon color="darkGray" @click="duplicateComponent()">-->
                    <!--<v-icon>mdi-content-copy</v-icon>-->
                    <!--</v-btn>-->
                </v-card-text>
            </v-card>
        </div>

        <delete-alert ref="confirm" />
    </div>
</template>

<script>
import moment from 'moment';
import store from '../../store/index';
import common from '../../constant/common-data';
import TextArea from './component-types/TextArea';
import TextField from './component-types/TextField';
import DropDown from './component-types/DropDown';
import CheckBox from './component-types/CheckBox';
import Radio from './component-types/Radio';
import Date from './component-types/Date';
import Time from './component-types/Time';
import TextEditor from './component-types/TextEditor';
import FileUpload from './component-types/FileUpload';
import FromDatabase from './component-types/FromDatabase';
import Competitor from './component-types/Competitor';
import CrmDropDown from './component-types/CrmDropdown';
import Courses from './component-types/Courses';
import SectionModel from '../../models/Component';
import DeleteAlert from '../../components/alert/ConfirmAlert.componet';
import Button from './component-types/Button.vue';

export default {
    name: 'EditJourneyComponent',
    store,
    common,
    components: {
        TextArea,
        TextField,
        DropDown,
        CheckBox,
        Radio,
        Date,
        Time,
        TextEditor,
        FileUpload,
        FromDatabase,
        Competitor,
        CrmDropDown,
        Courses,
        DeleteAlert,
        Button,
    },
    props: {
        selectedSection: Object,
        selectedComponent: Number,
        componentData: Object,
        saveJourneyState: Boolean,
    },
    data() {
        return {
            tab: null,
            inputTypes: common.inputTypes,
            dateActionPicker: false,
            valid: true,
            gridSizes: common.gridSizes,
            fileSizeUnits: common.fileSizeUnits,
            types: [],
            fileTypes: common.fileTypes,
            fieldLogic: [],
            visibleActions: common.visibleActions,
            enableActions: common.enableActions,
            operators: common.operators,
            document_types_set: common.documentTypes,
            visibilityError: false,
            enabilityError: false,
            validationError: false,
            editError: false,
            isEdited: false,
            component: {
                component_name: null,
                component_label: null,
                component_placeholder: null,
                component_entity: null,
                component_crm_field: null,
                component_type: null,
                component_default_value: null,
                component_default_file_size: 5, // Default file size 5
                component_default_file_size_unit: 'MB', // Default file size unit is MB
                is_admin_only: false,
                is_show_label: true,
                is_hide_component: false,
                is_disable: false,
                is_hidden: false,
                is_advance_condition: false,
                db_field: null,
                allowed_file_types: [], // for development
                file_size: 5,
                file_size_unit: 'MB',
                document_type: null,
                grid_size: 12,
                is_frontend_insertion: false,
                hide: true,
                is_full_width: true,
                crm_required: true,
                crm_drop_down: {
                    entity: null,
                    value_field: null,
                    display_field: null,
                    is_search_option: false,
                    is_multi_option: false,
                    conditions: [],
                },
                courses_conditions: [],
                component_data_set: [],
                advance_validation_rules: {
                    is_required: false,
                    field_type: null,
                    validation_rules: {
                        field_type: null,
                        value: null,
                        type: null,
                    },
                    enability_rules: [],
                    visibility_rules: [],
                    edit_rules: [],
                },
            },
        };
    },

    methods: {
        changeAction(model, index) {
            if (this.component.advance_validation_rules[model].length > 1) {
                this.component.advance_validation_rules[model].map((item, i) => {
                    if (i !== index) {
                        item.action = this.component.advance_validation_rules[model][index].action;
                    }
                });
            }
        },
        addCondition(value) {
            switch (value) {
                case 'enability':
                    this.component.advance_validation_rules.enability_rules.push({
                        parent_field: '',
                        value: null,
                        action: '',
                        operator: '',
                    });
                    break;

                case 'visibility':
                    this.component.advance_validation_rules.visibility_rules.push({
                        parent_field: '',
                        value: null,
                        action: '',
                        operator: '',
                    });
                    break;

                case 'edit_rules':
                    this.component.advance_validation_rules.edit_rules.push({
                        parent_field: '',
                        value: null,
                        action: '',
                        operator: '',
                    });
                    break;
            }
        },
        changeAdvanceCondition() {
            if (this.component.is_advance_condition === false) {
                this.component.advance_validation_rules.validation_rules = Object.assign(
                    {},
                    this.$options.data().component.advance_validation_rules.validation_rules
                );
                this.component.advance_validation_rules.enability_rules = Object.assign(
                    {},
                    this.$options.data().component.advance_validation_rules.enability_rules
                );
                this.component.advance_validation_rules.visibility_rules = Object.assign(
                    {},
                    this.$options.data().component.advance_validation_rules.visibility_rules
                );
                this.component.advance_validation_rules.edit_rules = Object.assign(
                    {},
                    this.$options.data().component.advance_validation_rules.edit_rules
                );
            }
        },
        getValidationRules(name) {
            return [(v) => !!v || `${name} is required`];
        },
        getValidation(type) {
            const rules = [];
            if (
                this.component.advance_validation_rules.validation_rules.field_type !== null &&
                this.component.advance_validation_rules.validation_rules.field_type !== '' &&
                this.component.advance_validation_rules.validation_rules.type !== null &&
                this.component.advance_validation_rules.validation_rules.type !== ''
            ) {
                if (
                    this.component.advance_validation_rules.validation_rules.type != 'futureDate' &&
                    this.component.advance_validation_rules.validation_rules.type != 'pastDate'
                ) {
                    rules.push((v) => !!v || `${type} is required`);
                }
                if (
                    this.component.advance_validation_rules.validation_rules.type == 'dateBetween'
                ) {
                    rules.push((v) => v.length != 2 || `${type} is should be two dates`);
                }
            }
            return rules;
        },
        changeFieldType() {
            switch (this.component.advance_validation_rules.validation_rules.field_type) {
                case 'number':
                    this.fieldLogic = common.numberLogic;
                    this.component.advance_validation_rules.validation_rules.value = null;
                    this.component.advance_validation_rules.validation_rules.type = null;
                    break;
                case 'text':
                    this.fieldLogic = common.itemsLength;
                    this.component.advance_validation_rules.validation_rules.value = null;
                    this.component.advance_validation_rules.validation_rules.type = null;
                    break;
                case 'date':
                    this.fieldLogic = common.dateActions;
                    this.component.advance_validation_rules.validation_rules.value = null;
                    this.component.advance_validation_rules.validation_rules.type = null;
                    break;
                case 'email':
                    this.component.advance_validation_rules.validation_rules.value = null;
                    this.component.advance_validation_rules.validation_rules.type = null;
                    this.fieldLogic = [];
                    break;
                case 'alphanumeric_with_space':
                    this.component.advance_validation_rules.validation_rules.value = null;
                    this.component.advance_validation_rules.validation_rules.type = null;
                    this.fieldLogic = common.itemsLength;
                    break;
                case 'alphanumeric_no_space':
                    this.component.advance_validation_rules.validation_rules.value = null;
                    this.component.advance_validation_rules.validation_rules.type = null;
                    this.fieldLogic = common.itemsLength;
                    break;
                default:
                    this.fieldLogic = [];
                    break;
            }
        },
        generateFieldName() {
            let name = this.component.component_label;
            this.component.component_name = name.replace(/[^A-Z0-9]/gi, '_').toLowerCase();
        },
        generateFileSize() {
            let fileSize = this.component.file_size.trim();
            this.component.file_size = fileSize.replace(/\s+/g, '');
        },
        generateFileSizeUnit() {
            let file_size_unit = this.component.file_size_unit;
            this.component.file_size_unit = file_size_unit;
        },
        changeType() {
            if (this.component.component_type === 'TEXT_FIELD') {
                this.types = common.textFieldTypes;
            } else if (this.component.component_type === 'DATE') {
                this.types = common.dateFieldTypes;
            } else {
                this.component.advance_validation_rules.validation_rules = Object.assign(
                    {},
                    this.$options.data().component.advance_validation_rules.validation_rules
                );
                this.types = [];
            }
            this.fieldLogic = [];
            this.component.crm_required = common.inputTypes.find(
                (item) => item.value === this.component.component_type
            ).crm_required;
            this.component.component_default_value = null;
            this.component.component_data_set = [];
            this.component.advance_validation_rules.validation_rules.value = null;
            this.component.advance_validation_rules.validation_rules.type = null;
        },
        addComponent() {
            if (this.$refs.form.validate()) {
                if (this.isEdited) {
                    const sectionComponent = new SectionModel();
                    let newComponent = sectionComponent.components[0];
                    newComponent.component_entity = this.$store.state.journey.defaultEntity;
                    newComponent.hide = !newComponent.hide;
                    this.component.hide = !this.component.hide;
                    const params = {
                        selectedComponent: this.selectedComponent,
                        component: this.component,
                        newComponent: newComponent,
                        selectedSection: this.selectedSection,
                    };
                    this.$store.commit('journey/addComponent', { ...params });
                } else {
                    const params = {
                        component: this.component,
                        selectedSection: this.selectedSection,
                    };
                    this.$store.commit('journey/addComponent', { ...params });
                    Object.assign(this.$data, this.$options.data());
                }
            }
        },
        updateComponent() {
            if (this.isEdited && this.$refs.form.validate()) {
                const params = {
                    component: this.component,
                    selectedComponent: this.selectedComponent,
                    selectedSection: this.selectedSection,
                };
                this.$store.commit('journey/updateComponent', { ...params });
            }
        },
        deleteComponent() {
            if (
                this.journeyState[this.selectedSection.tabKey].sections[
                    this.selectedSection.sectionKey
                ].components.length !== 1
            ) {
                this.$refs.confirm
                    .open('Are you sure', 'Do you want to delete this component ?', {
                        color: 'red',
                    })
                    .then((confirm) => {
                        if (confirm) {
                            const params = {
                                selectedComponent: this.selectedComponent,
                                selectedSection: this.selectedSection,
                            };
                            this.$store.commit('journey/deleteComponent', { ...params });
                            this.$emit('after-save-Journey', 'deleted');
                        }
                    });
            }
        },
        duplicateComponent() {
            const params = {
                component: this.component,
                selectedComponent: this.selectedComponent,
                selectedSection: this.selectedSection,
            };
            this.$store.commit('journey/duplicateComponent', { ...params });
            this.component.hide = !this.component.hide;
        },
        updateComponentValues(params) {
            this.component.component_default_value = params.component_default_value
                ? params.component_default_value
                : null;
            this.component.is_hidden = params.is_hidden ? params.is_hidden : false;
            this.component.is_show_label = params.is_show_label ? params.is_show_label : false;
            this.component.is_hide_component = params.is_hide_component
                ? params.is_hide_component
                : false;
            this.component.component_data_set = params.component_data_set
                ? params.component_data_set
                : null;

            if (this.component.component_type === 'CRM_DROP_DOWN') {
                this.component.crm_drop_down.entity = params.crm_drop_down.entity
                    ? params.crm_drop_down.entity
                    : null;
                this.component.crm_drop_down.value_field = params.crm_drop_down.value_field
                    ? params.crm_drop_down.value_field
                    : null;
                this.component.crm_drop_down.display_field = params.crm_drop_down.display_field
                    ? params.crm_drop_down.display_field
                    : null;
                this.component.crm_drop_down.is_search_option = params.crm_drop_down
                    .is_search_option
                    ? params.crm_drop_down.is_search_option
                    : null;
                this.component.crm_drop_down.conditions = params.crm_drop_down.conditions
                    ? params.crm_drop_down.conditions
                    : [];

                this.component.crm_drop_down.is_multi_option = params.crm_drop_down.is_multi_option
                    ? params.crm_drop_down.is_multi_option
                    : null;
            }
            if (this.component.component_type === 'BUTTON') {
                this.component.is_open_on_tab = params.is_open_on_tab
                    ? params.is_open_on_tab
                    : null;
                this.component.button_color = params.button_color ? params.button_color : 'primary';
            }
            if (this.component.component_type === 'COURSES') {
                this.component.courses_conditions = params.conditions ? params.conditions : [];
            }
            if (
                this.component.component_type === 'DATABASE' ||
                this.component.component_type === 'COMPETITOR'
            ) {
                this.component.db_field = params.db_field ? params.db_field : null;
                this.component.is_frontend_insertion = params.is_frontend_insertion
                    ? params.is_frontend_insertion
                    : null;
            }
        },

        resetValidation(value, index) {
            switch (value) {
                case 'type':
                    this.component.advance_validation_rules.validation_rules.field_type = null;
                    this.component.advance_validation_rules.validation_rules.value = null;
                    this.component.advance_validation_rules.validation_rules.type = null;
                    this.fieldLogic = [];
                    break;
                case 'visibility':
                    this.component.advance_validation_rules.visibility_rules.splice(index, 1);
                    break;
                case 'enability':
                    this.component.advance_validation_rules.enability_rules.splice(index, 1);
                    break;
                case 'edit_rules':
                    this.component.advance_validation_rules.edit_rules.splice(index, 1);
                    break;
                default:
                    break;
            }
        },
        setInitials() {
            if (this.componentData && this.componentData.component_type) {
                if (!Array.isArray(this.componentData.advance_validation_rules.enability_rules)) {
                    const conditions = this.componentData.advance_validation_rules.enability_rules;
                    this.componentData.advance_validation_rules.enability_rules = [];
                    this.componentData.advance_validation_rules.enability_rules.push(conditions);
                }
                if (!Array.isArray(this.componentData.advance_validation_rules.visibility_rules)) {
                    const conditions = this.componentData.advance_validation_rules.visibility_rules;

                    this.componentData.advance_validation_rules.visibility_rules = [];
                    this.componentData.advance_validation_rules.visibility_rules.push(conditions);
                }
                if (!Array.isArray(this.componentData.advance_validation_rules.edit_rules)) {
                    const conditions = this.componentData.advance_validation_rules.edit_rules;
                    this.componentData.advance_validation_rules.edit_rules = [];
                    this.componentData.advance_validation_rules.edit_rules.push(conditions);
                }

                this.component = this.componentData;
                this.isEdited = true;
                this.component.crm_required = common.inputTypes.find(
                    (item) => item.value === this.component.component_type
                ).crm_required;
                if (this.componentData.component_type === 'TEXT_FIELD') {
                    this.types = common.textFieldTypes;
                }
                if (this.componentData.component_type === 'DATE') {
                    this.types = common.dateFieldTypes;
                }
                if (
                    this.componentData.component_type === 'FILE_UPLOAD' ||
                    this.componentData.component_type === 'DOCUMENT'
                ) {
                    this.component.file_size = this.component.file_size
                        ? this.component.file_size
                        : this.defaultFileSize;
                    this.component.file_size_unit = this.component.file_size_unit
                        ? this.component.file_size_unit
                        : this.defaultFileSizeUnit;
                }

                switch (this.component.advance_validation_rules.validation_rules.field_type) {
                    case 'number':
                        this.fieldLogic = common.numberLogic;
                        break;
                    case 'date':
                        this.fieldLogic = common.dateActions;
                        break;
                    case 'text':
                        this.fieldLogic = common.itemsLength;
                        break;
                    case 'alphanumeric_no_space':
                        this.fieldLogic = common.itemsLength;
                        break;
                    case 'alphanumeric_with_space':
                        this.fieldLogic = common.itemsLength;
                        break;
                    case 'email':
                        this.component.advance_validation_rules.validation_rules.value = null;
                        this.component.advance_validation_rules.validation_rules.type = null;
                        this.fieldLogic = [];
                        break;
                    default:
                        this.fieldLogic = [];
                        break;
                }
            }
        },
        saveJourney() {
            if (this.saveJourneyState === true) {
                if (this.isEdited && this.$refs.form.validate()) {
                    const params = {
                        component: this.component,
                        selectedComponent: this.selectedComponent,
                        selectedSection: this.selectedSection,
                    };
                    this.$store.commit('journey/updateComponent', { ...params });

                    this.$store.commit('journey/minimizeCurrentComponent', this.selectedSection);
                    this.$emit('after-save-Journey', 'saved');
                }
                this.$emit('after-save-Journey', 'saved-failed');
            }
        },
        handleCloseDatePicker() {
            if (
                this.component.advance_validation_rules.validation_rules.type != 'dateBetween' ||
                this.component.advance_validation_rules.validation_rules.value.length == 2
            ) {
                this.dateActionPicker = false;
            }
        },
        changeFieldLogic() {
            if (
                ['futureDate', 'pastDate', 'minDate', 'maxDate', 'dateBetween'].includes(
                    this.component.advance_validation_rules.validation_rules.type
                )
            ) {
                this.component.advance_validation_rules.validation_rules.value = null;
                this.$refs.form.resetValidation();
            }
        },
    },
    mounted() {
        this.setInitials();
    },

    watch: {
        saveJourneyState() {
            this.saveJourney();
        },
    },
    computed: {
        journeyState: () => {
            return store.state.journey.journey;
        },
        uuid: () => {
            return store.state.journey.uuid;
        },
        entityTypes: () => {
            return store.state.journey.entityTypes;
        },
        documentTypes: () => {
            return store.state.journey.documentTypes;
        },
        currentUser() {
            return this.$store.state.auth.user;
        },
        isAdmin() {
            if (this.currentUser && this.currentUser.role) {
                return ['ADMIN'].includes(this.currentUser.role[0].name);
            }

            return false;
        },
        defaultFileSize() {
            return common.defaultFileSize;
        },
        defaultFileSizeUnit() {
            return common.defaultFileSizeUnit;
        },
        formatDate() {
            return this.component.advance_validation_rules.validation_rules.value
                ? this.component.advance_validation_rules.validation_rules.type == 'dateBetween'
                    ? `${moment(
                          this.component.advance_validation_rules.validation_rules.value[0]
                      ).format('DD-MM-YYYY')} & ${moment(
                          this.component.advance_validation_rules.validation_rules.value[1]
                      ).format('DD-MM-YYYY')}`
                    : moment(this.component.advance_validation_rules.validation_rules.value).format(
                          'DD-MM-YYYY'
                      )
                : '';
        },
    },
};
</script>
