<template>
    <v-container class="journey-details" v-if="journey.journey_name">
        <div
            class="d-sm-flex d-xs-block justify-space-between align-center text-center my-10 primary--text"
        >
            <div class="d-flex flex-column align-sm-start d-xs-block align-center">
                <p class="header-text mb-0 journey-header-text">{{ journey.journey_name }}</p>
                <p class="text-h5 journey-header-text">{{ journey.academic_year.period }}</p>
            </div>

            <div
                class="d-flex flex-column align-sm-start d-xs-block align-center"
                v-if="isEditMood"
            >
                <p class="text-h6 font-weight-bold mb-0 journey-header-text">
                    #{{ journey.application.applicationNumber }}
                </p>
                <v-chip
                    class="secondary-font lighten-2"
                    :color="getStatusColor(journey.application.status)"
                    dark
                >
                    {{ journey.application.status }}
                </v-chip>
            </div>
        </div>

        <v-tabs
            :key="tabsKey"
            vertical
            slider-size="0"
            class="journey-tabs"
            v-model="activeTab"
            v-if="initiated"
        >
            <v-tab
                v-for="(tab, tabIndex) in journeyFormData"
                :key="tabIndex"
                @click.native.stop="checkValueChanged()"
                :disabled="disableJourneyItems(tab, tabIndex)"
                v-show="!hideJourneyItems(tab, tabIndex)"
            >
                {{ tab.tab_display_name }}
                <v-icon right large v-if="isCompletedTab(tabIndex)">
                    mdi-checkbox-marked-circle-outline
                </v-icon>
            </v-tab>
            <v-tab-item v-for="(tabItem, index) in journeyFormData" :key="index">
                <!-- :disabled="disableJourneyItems(tabItem, index)"
                v-show="!hideJourneyItems(tabItem, index)"
            > -->
                <journey-item
                    :ref="tabItem.tab_name"
                    :journeyItem="tabItem"
                    :key="index + journeyItemKey"
                    :sections="journeyTabs[index].sections"
                    :allTabs="journeyTabs"
                    :isEditMood="isEditMood"
                    v-on:journeySections="journeySections"
                    v-on:appearanceJourneySections="appearanceJourneySections"
                    v-on:saveApplication="saveApplication"
                    v-on:gotoNextTab="gotoNextTab"
                    :activeTab="activeTab"
                    :appearanceSections="appearanceTabs[index].sections"
                    :eligibleForSubmit="eligibleForSubmit"
                    :isVisible="!hideJourneyItems(tabItem, index)"
                    :isDisable="disableJourneyItems(tabItem, index)"
                    :applicationSubmit="
                        journey.application ? journey.application.applicationSubmitted : 'No'
                    "
                />
                <!-- :isVisible="!hideJourneyItems(tabItem, index)"
                    :isDisable="disableJourneyItems(tabItem, index)" -->
            </v-tab-item>
        </v-tabs>
        <div class="text-center">
            <v-dialog :value="triggerAlert" width="50%" content-class="overflow-hidden" persistent>
                <v-card class="secondary-font">
                    <v-card-text class="pa-4">
                        Do you want to go to another section without saving the changes?
                    </v-card-text>
                    <v-card-actions class="pt-0 flex-wrap"
                        ><v-row>
                            <v-col>
                                <v-btn color="grey" block @click="proceedWithSaving"
                                    >No. Let me save changes.</v-btn
                                ></v-col
                            >
                            <v-col>
                                <v-btn color="primary darken-1" block @click="proceedWithOutSaving"
                                    >Yes. Proceed</v-btn
                                ></v-col
                            ></v-row
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-container>
</template>

<script>
import JourneyItem from '../../components/application/JourneyItem';
import statuses from '../../config/statuses';
import commonService from '../../services/common-service';
import journeyService from '../../services/journey-service';
import notificationsUtility from '../../utilities/notifications-utility';
import advancedValidation from '../../assets/scripts/advanced-validation';
import commonData from '../../constant/common-data';

export default {
    name: 'ApplicationCreate',

    components: { JourneyItem },

    data() {
        return {
            journey: {},
            statusTypes: statuses,
            journeyTabs: [],
            activeTab: 0,
            previousActiveTab: 0,
            onlyEnabledTab: [],
            appearanceTabs: [],
            initiated: false,
            eligibleForSubmit: false,
            applicationOldData: {},
            dialog: false,
            proceedCheck: false,
            latestActiveTab: null,
            journeyItemKey: 1,
            tabsKey: 1,
        };
    },

    computed: {
        isAdmin() {
            let logAsUserStatus = localStorage.getItem('log_as_user_status');
            let logAsUser = localStorage.getItem('log_as_user');

            let currentUser =
                logAsUserStatus && logAsUserStatus === 'true'
                    ? JSON.parse(logAsUser)
                    : this.$store.state.auth.user;

            if (currentUser && currentUser.role) {
                return currentUser.role[0].name === 'ADMIN' ? true : false;
            }

            return false;
        },

        journeyFormData() {
            return JSON.parse(this.journey.form_data);
        },

        isEditMood() {
            return this.$route.path.includes('edit') ? true : false;
        },

        application() {
            return this.$store.state.common.curentApplication;
        },

        site() {
            return this.$store.state.common.site;
        },

        isCompletedTab() {
            return (tabIndex) => {
                return this.isTabCompleted(tabIndex);
            };
        },

        triggerAlert() {
            return this.$store.state.journey.triggerAlert;
        },
    },

    methods: {
        getUpdatedCrmValues() {
            let applicationId = this.$route.params.id;

            journeyService
                .editApplication(applicationId)
                .then((response) => {
                    this.tabsKey++;
                    if (response.data.meta.status_code === 200) {
                        this.journey = response.data.data;
                        this.applicationOldData = response.data.data;

                        this.$store.dispatch('journey/setActiveJourney', this.journey.id);

                        new Promise((resolve, reject) => {
                            resolve(this.createSectionArray());
                        });

                        const createAppearanceTabArray = new Promise((resolve, reject) => {
                            resolve(this.createAppearanceTabArray());
                        });

                        if (createAppearanceTabArray) {
                            this.initiated = true;
                        }

                        setTimeout(() => {
                            localStorage.setItem(
                                'applicationDetails',
                                JSON.stringify(this.journeyTabs)
                            );
                        }, 2000);

                        let nextActivetab = ++this.activeTab;
                        let checkNextTab = this.checkIfNextTabIsVisibleActiveNext(
                            JSON.parse(response.data.data.form_data),
                            nextActivetab
                        );
                        this.activeTab = checkNextTab;
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },

        isTabCompleted(tabIndex) {
            let tabDetails = this.appearanceTabs[tabIndex];
            let allComponents = [];
            let excludeComponents = ['LABEL', 'SPACER', 'APPLICATION_STATUS', 'TEXT_EDITOR'];

            tabDetails.sections.forEach((section) => {
                section.components.forEach((component) => {
                    if (component.component_type === 'PAYMENT') {
                        allComponents.push(component);
                    } else {
                        if (
                            !excludeComponents.includes(component.component_type) &&
                            component.is_required
                        ) {
                            allComponents.push(component);
                        }
                    }
                });
            });

            let emptyComponents = allComponents.filter((component) => {
                if (!component.is_disable && !component.is_hidden) {
                    let componentValue = component.value;

                    if (Array.isArray(component.value)) {
                        componentValue = component.value.length > 0 ? component.value : null;
                    }

                    return componentValue === null || componentValue === '';
                }
            });

            return emptyComponents.length > 0 ? false : true;
        },

        isTabCompletedOld(tabIndex) {
            let tabDetails = JSON.parse(this.journey.form_data)[tabIndex];
            let allComponents = [];
            let excludeComponents = ['LABEL', 'SPACER', 'APPLICATION_STATUS', 'TEXT_EDITOR'];
            if (typeof tabDetails.sections !== 'undefined') {
                tabDetails.sections.forEach((section) => {
                    section.components.forEach((component) => {
                        if (component.component_type === 'PAYMENT') {
                            allComponents.push(component);
                        } else {
                            if (
                                !excludeComponents.includes(component.component_type) &&
                                component.is_required
                            ) {
                                allComponents.push(component);
                            }
                        }
                    });
                });
            }

            let emptyComponents = allComponents.filter((component) => {
                if (!component.is_disable && !component.is_hidden) {
                    let componentValue = component.value;

                    if (Array.isArray(component.value)) {
                        componentValue = component.value.length > 0 ? component.value : null;
                    }

                    return componentValue === null || componentValue === '';
                }
            });
            return emptyComponents.length > 0 ? false : true;
        },

        async getJourneyDetails() {
            this.journeyUuid = this.$route.params.id;
            journeyService
                .getJourneyByUuid(this.journeyUuid)
                .then((response) => {
                    if (response.data.meta.status_code === 200) {
                        this.journey = response.data.data;
                        this.applicationOldData = response.data.data;

                        this.$store.dispatch('journey/setActiveJourney', this.journey.id);

                        this.$store.dispatch(
                            'common/setApplicationDetails',
                            this.journey['application'] ? this.journey['application'] : null
                        );

                        this.setThemeColor();

                        new Promise((resolve, reject) => {
                            resolve(this.createSectionArray());
                        });

                        const createAppearanceTabArray = new Promise((resolve, reject) => {
                            resolve(this.createAppearanceTabArray());
                        });

                        if (createAppearanceTabArray) {
                            this.initiated = true;
                        }

                        this.getOnlyEnabledTabInCreateMood();

                        setTimeout(() => {
                            localStorage.setItem(
                                'applicationDetails',
                                JSON.stringify(this.journeyTabs)
                            );
                        }, 2000);
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },

        async editApplication() {
            let applicationId = this.$route.params.id;
            journeyService
                .editApplication(applicationId)
                .then((response) => {
                    if (response.data.meta.status_code === 200) {
                        this.journey = response.data.data;
                        this.applicationOldData = response.data.data;
                        this.$store.dispatch('journey/setActiveJourney', this.journey.id);

                        this.$store.dispatch(
                            'common/setApplicationDetails',
                            this.journey['application'] ? this.journey['application'] : null
                        );
                        this.setThemeColor();

                        new Promise((resolve, reject) => {
                            resolve(this.createSectionArray());
                        });

                        const createAppearanceTabArray = new Promise((resolve, reject) => {
                            resolve(this.createAppearanceTabArray());
                        });

                        if (createAppearanceTabArray) {
                            this.appearanceJourneySections();
                            this.initiated = true;
                        }

                        setTimeout(() => {
                            localStorage.setItem(
                                'applicationDetails',
                                JSON.stringify(this.journeyTabs)
                            );
                        }, 2000);

                        let paymentData = localStorage.getItem('paymentData')
                            ? JSON.parse(localStorage.getItem('paymentData'))
                            : null;

                        if (paymentData) {
                            this.activeTab = parseInt(paymentData.activeTab);
                            this.previousActiveTab = this.activeTab;
                        } else if (localStorage.getItem('nextTab')) {
                            this.activeTab = parseInt(localStorage.getItem('nextTab'));
                            this.previousActiveTab = this.activeTab;

                            localStorage.removeItem('nextTab');
                        }
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },

        getStatusColor(status) {
            let filteredStatus = this.statusTypes.filter((value) => {
                return value.status_name === status;
            });

            if (filteredStatus.length > 0) {
                return filteredStatus[0].color;
            }

            return '';
        },

        setThemeColor() {
            document
                .querySelector(':root')
                .style.setProperty('--journeyColor', this.getJourneyColor());
        },

        getJourneyColor() {
            return this.journey.theme ? this.journey.theme : '#00859b';
        },

        createSectionArray() {
            this.journeyTabs = [];
            return new Promise((resolve, reject) => {
                this.journeyFormData.forEach((tab, tabIndex) => {
                    // Tab Sections
                    let tabSections = [];
                    tab.sections.forEach((section, sectionIndex) => {
                        // Section components
                        if (!this.isAdmin && section.status === 'INACTIVE') {
                            return;
                        }

                        let sectionComponents = [];
                        section.components.forEach((component, componentIndex) => {
                            if (!this.isAdmin && component.is_admin_only) {
                                return;
                            }

                            let defaultValue = component.component_default_value;

                            if (component.component_type === 'FILE_UPLOAD') {
                                defaultValue = defaultValue ? defaultValue.id : null;
                            }

                            let componentItem = {
                                name: component.component_name,
                                value: defaultValue,
                                crm_field: component.component_crm_field,
                                entity: component.component_entity,
                                component_type: component.component_type,
                                is_frontend_insertion: component.is_frontend_insertion
                                    ? component.is_frontend_insertion
                                    : null,
                                is_required: component.advance_validation_rules.is_required,
                                is_disable: false,
                                is_hidden: false,
                            };

                            sectionComponents.push(componentItem);
                        });

                        // End Section components

                        let sectionItem = {
                            section_name: section.section_name,
                            components: sectionComponents,
                            is_hidden: false,
                            is_disable: false,
                        };
                        tabSections.push(sectionItem);
                    });
                    // End Tab Sections

                    if (
                        tab.is_submit &&
                        this.application &&
                        this.application.applicationSubmitted !== 'Yes'
                    ) {
                        let componentItem = {
                            name: 'applicationSubmitted',
                            value: 'Yes',
                            crm_field: 'applicationSubmitted',
                            entity: this.journey.entity_type,
                            component_type: 'SUBMIT',
                            is_frontend_insertion: null,
                            is_required: true,
                            is_disable: false,
                            is_hidden: false,
                        };

                        tabSections[0].components.push(componentItem);
                    }

                    let tabItem = {
                        tab_name: tab.tab_name,
                        sections: tabSections,
                        is_hidden: false,
                        is_disable: false,
                    };

                    this.journeyTabs.push(tabItem);
                });

                localStorage.setItem('applicationDetails', JSON.stringify(this.journeyTabs));

                resolve(this.journeyTabs);
            });
        },

        createAppearanceTabArray() {
            this.appearanceTabs = [];
            return new Promise((resolve, reject) => {
                this.journeyFormData.forEach((tab, tabIndex) => {
                    let appearanceTabSections = [];
                    tab.sections.forEach((section, sectionIndex) => {
                        // Section components
                        if (!this.isAdmin && section.status === 'INACTIVE') {
                            return;
                        }

                        let appearanceSectionComponents = [];
                        section.components.forEach((component, componentIndex) => {
                            if (!this.isAdmin && component.is_admin_only) {
                                return;
                            }

                            let defaultValue = component.component_default_value;

                            if (component.component_type === 'FILE_UPLOAD') {
                                defaultValue = defaultValue ? defaultValue.id : null;
                            }

                            let appearanceComponentItem = {
                                component_name: component.component_name,
                                is_disable: advancedValidation.advancedConditionsEnability(
                                    component.is_disable,
                                    component,
                                    this.isEditMood,
                                    this.journeyTabs,
                                    this.journeyTabs[tabIndex].sections,
                                    this.isAdmin,
                                    this.journey.application
                                        ? this.journey.application.applicationSubmitted
                                        : 'No'
                                ),
                                is_hidden: !advancedValidation.advancedConditionsVisibility(
                                    component.is_hidden,
                                    component,
                                    this.isEditMood,
                                    this.journeyTabs,
                                    this.journeyTabs[tabIndex].sections,
                                    this.isAdmin,
                                    this.journey.application
                                        ? this.journey.application.applicationSubmitted
                                        : 'No'
                                ),
                                value: defaultValue,
                                component_type: component.component_type,
                                is_required: component.advance_validation_rules.is_required,
                            };

                            appearanceSectionComponents.push(appearanceComponentItem);
                        });

                        let appearanceSectionItem = {
                            section_name: section.section_name,
                            components: appearanceSectionComponents,
                            is_disable: advancedValidation.advancedConditionsEnabilityTabSection(
                                false,
                                section,
                                this.isEditMood,
                                this.journeyTabs,
                                this.journeyTabs[tabIndex].sections,
                                this.isAdmin,
                                this.journey.application
                                    ? this.journey.application.applicationSubmitted
                                    : 'No'
                            ),
                            is_hidden: !advancedValidation.advancedConditionsVisibilityTabSection(
                                false,
                                section,
                                this.isEditMood,
                                this.journeyTabs,
                                this.journeyTabs[tabIndex].sections,
                                this.isAdmin,
                                this.journey.application
                                    ? this.journey.application.applicationSubmitted
                                    : 'No'
                            ),
                        };
                        appearanceTabSections.push(appearanceSectionItem);
                    });

                    let tab_is_disable = advancedValidation.advancedConditionsEnabilityTabSection(
                        false,
                        tab,
                        this.isEditMood,
                        this.journeyTabs,
                        this.journeyTabs[tabIndex],
                        this.isAdmin,
                        this.journey.application
                            ? this.journey.application.applicationSubmitted
                            : 'No'
                    );
                    let tab_is_hidden = !advancedValidation.advancedConditionsVisibilityTabSection(
                        false,
                        tab,
                        this.isEditMood,
                        this.journeyTabs,
                        this.journeyTabs[tabIndex],
                        this.isAdmin,
                        this.journey.application
                            ? this.journey.application.applicationSubmitted
                            : 'No'
                    );

                    // End Tab Sections
                    let appearanceTabItem = {
                        tab_name: tab.tab_name,
                        sections: appearanceTabSections,
                        is_disable: tab_is_disable,
                        is_hidden: tab_is_hidden,
                    };
                    this.appearanceTabs.push(appearanceTabItem);
                });
                resolve(this.appearanceTabs);
            });
        },

        journeySections(data) {
            this.journeyTabs.forEach((tab) => {
                if (tab.tab_name === data.key) {
                    tab['sections'] = data.value;
                }
                return;
            });
        },

        appearanceJourneySections(data = null) {
            this.appearanceTabs.forEach((tab, tabIndex) => {
                if (data && tab.tab_name === data.key) {
                    tab['sections'] = data.value;
                }
                this.journeyTabs[tabIndex]['is_disable'] = tab.is_disable;
                this.journeyTabs[tabIndex]['is_hidden'] = tab.is_hidden;
                tab.sections.forEach((section, sectionIndex) => {
                    this.journeyTabs[tabIndex]['sections'][sectionIndex]['is_disable'] =
                        section.is_disable;
                    this.journeyTabs[tabIndex]['sections'][sectionIndex]['is_hidden'] =
                        section.is_hidden;
                    section.components.forEach((component, componentIndex) => {
                        this.journeyTabs[tabIndex]['sections'][sectionIndex]['components'][
                            componentIndex
                        ]['is_disable'] = component.is_disable;
                        this.journeyTabs[tabIndex]['sections'][sectionIndex]['components'][
                            componentIndex
                        ]['is_hidden'] = component.is_hidden;
                    });
                });
                return;
            });
        },

        saveApplication() {
            this.checkEligibleForSubmit();
            localStorage.setItem('applicationDetails', JSON.stringify(this.journeyTabs));

            let tabDetails = this.journeyTabs[this.activeTab];
            let allComponents = [];

            tabDetails.sections.forEach((section) => {
                section.components.forEach((component) => {
                    allComponents.push(component);
                });
            });

            let isSubmit = false;
            // debugger;
            if (allComponents.length > 0) {
                let submitAvailable = allComponents.filter((component) => {
                    if (component.crm_field === 'applicationSubmitted') {
                        return component;
                    }
                });

                if (submitAvailable.length > 0) {
                    isSubmit = true;
                    localStorage.setItem('nextTab', this.activeTab + 1);
                }

                let payload = {
                    components: allComponents,
                    applicationId: this.journey['application']
                        ? this.journey['application']['applicationID']
                        : null,
                    journeyType: this.journey['journey_type'],
                    academicYear: this.journey['academic_year'],
                    entityType: this.journey['entity_type'],
                    journeyId: this.journey['id'],
                    canApplyJourneyMultipleTimes: commonData.canApplyJourneyMultipleTimes,
                    applicationOldData: this.applicationOldData,
                };
                payload.user =
                    localStorage.getItem('log_as_user_status') &&
                    localStorage.getItem('log_as_user_status') === 'true'
                        ? JSON.parse(localStorage.getItem('log_as_user'))
                        : this.$store.state.auth.user;

                payload.utmData = (() => {
                    const utmData = localStorage.getItem('utmData');
                    return utmData && utmData.trim() !== '' ? JSON.parse(utmData) : '';
                })();

                commonService
                    .post('application/create', payload)
                    .then((response) => {
                        if (response.status === 'exists') {
                            notificationsUtility.pushNotifications([
                                {
                                    message: response.message,
                                    type: 'error',
                                },
                            ]);

                            this.$router.push(`/${this.site}/`);
                        } else {
                            if (!this.isEditMood && !payload['applicationId']) {
                                localStorage.setItem('nextTab', this.activeTab + 1);
                                let applicationIdWithJourneyId =
                                    response.data.id + this.journey['id'];
                                window.location.href = `/${this.site}/application/edit/${applicationIdWithJourneyId}`;
                            }

                            if (isSubmit) {
                                location.reload();
                            } else {
                                this.getUpdatedCrmValues();
                            }

                            notificationsUtility.pushNotifications([
                                {
                                    message: 'Application details saved successfully',
                                    type: 'success',
                                },
                            ]);
                        }
                    })
                    .catch((error) => {
                        if (error.response.status !== 503) {
                            notificationsUtility.pushNotifications([
                                {
                                    message:
                                        'Something went wrong. Please try contact server admin',
                                    type: 'error',
                                },
                            ]);
                        }
                    });
            } else {
                this.activeTab = this.activeTab + 1;
                this.checkIfNextTabIsVisible(this.activeTab);
            }
        },

        checkIfNextTabIsVisibleActiveNext(tabData, nextTab) {
            let x = 0;
            let y = 0;
            let length = tabData.length - 1;
            let tab = nextTab;

            for (let i = --nextTab; i <= tabData.length; ++i) {
                x++;

                if (tabData[i] && tabData[i].is_hidden) {
                    if (tab === length) {
                        return 0;
                    }
                    y++;
                }

                if (x > y) {
                    break;
                }
            }

            return nextTab + x;
        },
        checkIfNextTabIsVisible(nextTab) {
            let x = 0;
            let y = 0;
            for (let i = nextTab--; i <= this.appearanceTabs.length; i++) {
                x++;
                if (this.appearanceTabs[i].is_hidden) {
                    y++;
                }
                if (x > y) {
                    break;
                }
            }
            this.activeTab = nextTab + x;
        },
        checkAlertSelection(activeTab) {
            this.latestActiveTab = activeTab;
        },
        proceedWithSaving() {
            this.$store.commit('journey/setTriggerAlert', false);
        },
        async proceedWithOutSaving() {
            this.$store.commit('journey/setTriggerAlert', false);
            this.proceedCheck = true;

            if (this.isEditMood) {
                await this.editApplication();
            } else {
                await this.getJourneyDetails();
            }
            this.journeyItemKey++;
            //set active Tab to the clicked one
            this.activeTab = this.latestActiveTab;
        },

        checkValueChanged() {
            let tabDataComponents = [];
            let currentTabComponents = [];

            this.checkEligibleForSubmit();

            let currrentAppData = this.journeyTabs;
            let originalApplicationDetails = localStorage.getItem('applicationDetails')
                ? localStorage.getItem('applicationDetails')
                : null;
            let prevAppData = JSON.parse(originalApplicationDetails);

            currrentAppData.forEach((component) => {
                for (let i = 0; i < component.sections.length; i++) {
                    for (let j = 0; j < component.sections[i].components.length; j++) {
                        currentTabComponents.push({
                            component: component.sections[i].components[j].name,
                            value: component.sections[i].components[j].value,
                        });
                    }
                }
            });

            prevAppData.forEach((component) => {
                for (let i = 0; i < component.sections.length; i++) {
                    for (let j = 0; j < component.sections[i].components.length; j++) {
                        tabDataComponents.push({
                            component: component.sections[i].components[j].name,
                            value: component.sections[i].components[j].value,
                        });
                    }
                }
            });

            if (JSON.stringify(tabDataComponents) !== JSON.stringify(currentTabComponents)) {
                this.checkAlertSelection(this.activeTab);
                this.$store.commit('journey/setTriggerAlert', true);
                this.activeTab = this.previousActiveTab;
            } else {
                this.previousActiveTab = this.activeTab;
            }

            if (this.proceedCheck) {
                this.previousActiveTab = this.activeTab;
            }
        },

        gotoNextTab() {
            this.activeTab++;
            this.previousActiveTab = this.activeTab;
        },

        getOnlyEnabledTabInCreateMood() {
            let allItems = JSON.parse(this.journey.form_data);
            allItems.forEach((element, index) => {
                if (element.read_only) {
                    allItems.splice(index, 1);
                }
            });

            if (allItems && allItems.length > 0) {
                this.onlyEnabledTab = allItems[0];
            }
        },
        isDisabled(currentTab) {
            if (!this.isEditMood && this.onlyEnabledTab.tab_name) {
                if (currentTab.read_only) {
                    return false;
                }

                if (currentTab.tab_name === this.onlyEnabledTab.tab_name) {
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        },
        hideJourneyItems(currentTab, tabIndex) {
            let is_hidden = !advancedValidation.advancedConditionsVisibilityTabSection(
                false,
                currentTab,
                this.isEditMood,
                this.journeyTabs,
                this.journeyTabs[tabIndex].sections,
                this.isAdmin,
                this.journey.application ? this.journey.application.applicationSubmitted : 'No'
            );
            if (is_hidden) {
                return true;
            }
            return false;
        },

        disableJourneyItems(currentTab, tabIndex) {
            let is_disable = advancedValidation.advancedConditionsEnabilityTabSection(
                false,
                currentTab,
                this.isEditMood,
                this.journeyTabs,
                this.journeyTabs[tabIndex].sections,
                this.isAdmin,
                this.journey.application ? this.journey.application.applicationSubmitted : 'No'
            );

            if (!is_disable) {
                return true;
            }

            if (!this.isEditMood && this.onlyEnabledTab.tab_name) {
                if (currentTab.read_only) {
                    return false;
                }

                if (currentTab.tab_name === this.onlyEnabledTab.tab_name) {
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        },

        checkEligibleForSubmit() {
            this.eligibleForSubmit = true;
            // if()
            this.journeyFormData.forEach((tab, tabIndex) => {
                if (tab.eligible_submit) {
                    if (!this.isTabCompleted(tabIndex)) {
                        this.eligibleForSubmit = false;
                    }
                }
            });
        },
    },

    async created() {
        localStorage.removeItem('applicationDetails');

        if (this.isEditMood) {
            await this.editApplication();
        } else {
            await this.getJourneyDetails();
        }
    },
};
</script>
