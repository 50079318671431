function SectionComponent() {
    this.section_name = 'untitledSection';
    this.section_display_name = 'Untitled Section';
    this.section_entity = 'Contact';
    this.is_show_title = true;
    this.is_enable = true;
    this.is_hidden = false;
    this.status = 'ACTIVE';
    this.advance_validation_rules = {
        is_required: false,
        field_type: null,
        enability_rules: [],
        visibility_rules: [],
    };
    this.is_advance_condition = false;
    this.components = [
        {
            component_name: 'text_field',
            component_label: 'Text Field',
            component_placeholder: 'Text Field',
            component_entity: null,
            component_crm_field: null,
            component_type: 'TEXT_FIELD',
            component_default_value: 'Text Field',
            component_ref: (Math.random() + 1).toString(36).substring(7),
            is_admin_only: false,
            is_show_label: true,
            is_hide_component: false,
            is_disable: false,
            is_hidden: false,
            is_advance_condition: false,
            db_field: null,
            allowed_file_types: [],
            file_size: 5,
            file_size_unit: 'MB',
            document_type: null,
            hide: true,
            grid_size: 12,
            is_frontend_insertion: false,
            crm_required: true,
            crm_drop_down: {
                entity: null,
                value_field: null,
                display_field: null,
                is_search_option: false,
                is_multi_option: false,
            },
            courses_conditions: [],
            component_data_set: [],
            advance_validation_rules: {
                is_required: false,
                field_type: null,
                validation_rules: {
                    field_type: null,
                    value: null,
                    type: null,
                },
                enability_rules: [],
                visibility_rules: [],
                edit_rules: [],
            },
        },
    ];
}

export default SectionComponent;
