<template>
    <v-container class="boundry height100">
        <v-row class="d-flex align-center justify-center height100">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4" class="primary--text text-center">
                <v-icon class="icon-200 mb-8" color="success" v-if="this.activationSuccess">
                    mdi-checkbox-marked-circle-outline
                </v-icon>
                <v-icon class="icon-200 mb-8" color="error" v-if="!this.activationSuccess">
                    mdi-close-circle-outline
                </v-icon>
                <br />
                <p class="display-1">
                    {{ this.activationSuccess ? 'Activated!' : 'Link Expired' }}
                </p>
                <p class="secondary-font">
                    {{
                        this.activationSuccess
                            ? 'Your account has been activated. Please check your mail to receive your password.'
                            : 'Your account activation link has been expired'
                    }}
                </p>

                <v-btn
                    color="secondary"
                    @click="validate"
                    elevation="0"
                    class="text-capitalize"
                    large
                    :to="`/${site}/login`"
                >
                    Sign In
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'AccountActivatedResponse',
    data() {
        return {
            logo_url: require('@/assets/logo/BSE_logo_horizontal_dark.png'),
        };
    },
    computed: {
        activationSuccess() {
            return this.$route.params.status === '1';
        },
        site() {
            return this.$store.state.common.site;
        },
    },
};
</script>
