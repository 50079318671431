<template>
    <div v-bind:class="{ 'mb-8': currentDocuments.length > 0 }">
        <input-label :component="component" :isRequired="isRequired" />

        <v-file-input
            :ref="component.component_name"
            prepend-icon=""
            solo
            flat
            outlined
            dense
            @change="uploadFile"
            @update:error="cancelFileUpload"
            :rules="!$attrs.hidden && !enability ? validations : []"
            :required="isRequired"
            :disabled="enability"
            :placeholder="`Click to select and upload ${component.component_label}`"
            v-model="applicationDocument"
            :accept="setFileTypes()"
            :hide-input="component.is_hide_component"
        ></v-file-input>

        <div>
            <!-- <v-chip
                v-for="(document, index) in currentDocuments"
                :key="index"
                class="mr-2 mb-1"
                :close="enability || component.is_hide_component ? false : true"
                @click:close="deleteDocument(document)"
                outlined
                color="primary"
                :href="document.fileUrl"
                target="_blank"
            > -->
            <v-chip
                v-for="(document, index) in currentDocuments"
                :key="index"
                class="mr-2 mb-1"
                outlined
                color="primary"
                :href="document.fileUrl"
                target="_blank"
            >
                {{ document.fileName }}
            </v-chip>
        </div>
    </div>
</template>

<script>
import notificationsUtility from '../../../utilities/notifications-utility';
import commonService from '../../../services/common-service';

export default {
    name: 'DocumentComponent',

    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        validations: {
            type: [Object, Array],
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            convertedFile: null,
            currentDocuments: [],
            applicationDocument: null,
            file_size_in_bytes: 0,
        };
    },

    computed: {
        application() {
            return this.$store.state.common.curentApplication;
        },
    },

    watch: {
        enability() {
            this.appearanceInputChanged();
        },
        visibility() {
            this.appearanceInputChanged();
        },
    },

    methods: {
        setFileTypes() {
            let fileTypes = '';
            this.component.allowed_file_types.forEach((fileType) => {
                fileTypes += '.' + fileType + ',';
            });

            return fileTypes ? fileTypes.slice(0, -1) : null;
        },

        inputChanged() {
            let inputValue = {
                key: this.component.component_name,
                value: this.currentDocuments.length > 0 ? this.currentDocuments : [],
            };

            this.$emit('inputChanged', inputValue);
            this.appearanceInputChanged();
        },

        appearanceInputChanged() {
            let conditions = {
                component_name: this.component.component_name,
                is_disable: this.enability,
                is_hidden: this.visibility,
                value: this.currentDocuments.length > 0 ? this.currentDocuments : [],
            };

            this.$emit('appearanceInputChanged', conditions);
        },
        calculateSizeInKB() {
            let fileSizeInBytes;
            if (this.component.file_size_unit != null && this.component.file_size != null) {
                // Convert file size to bytes based on the unit
                switch (this.component.file_size_unit.toUpperCase()) {
                    case 'BYTES':
                        fileSizeInBytes = this.component.file_size;
                        break;
                    case 'KB':
                        fileSizeInBytes = this.component.file_size * 1024;
                        break;
                    case 'MB':
                        fileSizeInBytes = this.component.file_size * 1024 * 1024;
                        break;
                    case 'GB':
                        fileSizeInBytes = this.component.file_size * 1024 * 1024 * 1024;
                        break;
                    default:
                        fileSizeInBytes = this.component.file_size;
                }
                this.file_size_in_bytes = fileSizeInBytes;
            } else {
                this.file_size_in_bytes = 5 * 1024 * 1024;
            }
            return fileSizeInBytes;
        },
        uploadFile(selectedFile) {
            this.calculateSizeInKB();
            // if (selectedFile && selectedFile.size < 5 * 1024 * 1024) {
            if (selectedFile && selectedFile.size < this.file_size_in_bytes) {
                let checkFileExtention = this.checkFileExtention(selectedFile.name);

                if (checkFileExtention) {
                    if (this.application.applicationID) {
                        this.convertToBase64File(selectedFile)
                            .then((base64File) => {
                                let payload = {
                                    attachement: {
                                        name: selectedFile.name,
                                        type: selectedFile.type,
                                        size: selectedFile.size,
                                        role: 'Attachment',
                                        relatedType: 'Document',
                                        file: base64File,
                                        field: 'file',
                                    },
                                    document: {
                                        type: this.component.document_type,
                                        name: this.component.document_type,
                                        status: 'Active',
                                    },
                                    applicationId: this.application.applicationID,
                                    applicationEntity: this.application.entity,
                                };

                                payload['document'][this.component.component_crm_field] =
                                    this.application.applicationID;

                                commonService
                                    .post(`/upload-document`, payload)
                                    .then((response) => {
                                        this.currentDocuments = response.data;
                                        this.applicationDocument =
                                            this.currentDocuments.length > 0 ? [] : null;
                                        this.inputChanged();
                                    })
                                    .catch((error) => {
                                        if (error.response.status !== 503) {
                                            notificationsUtility.pushNotifications([
                                                {
                                                    message:
                                                        'Something went wrong. Please try contact server admin',
                                                    type: 'error',
                                                },
                                            ]);
                                        }
                                    });
                            })
                            .catch((error) => {
                                if (error.response.status !== 503) {
                                    notificationsUtility.pushNotifications([
                                        {
                                            message:
                                                'Something went wrong. Please try contact server admin',
                                            type: 'error',
                                        },
                                    ]);
                                }
                            });
                    } else {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Please save application before upload documents.',
                                type: 'error',
                            },
                        ]);
                    }
                } else {
                    this.$refs[this.component.component_name].reset();

                    notificationsUtility.pushNotifications([
                        {
                            message: 'Invalid document type.',
                            type: 'error',
                        },
                    ]);
                }
            }
        },

        deleteDocument(document) {
            let payload = {
                documentId: document.id,
                applicationId: this.application.applicationID,
                applicationEntity: this.application.entity,
                documentType: document.type,
            };

            commonService
                .post(`/delete-document`, payload)
                .then((response) => {
                    this.currentDocuments = response.data;
                    this.applicationDocument = this.currentDocuments.length > 0 ? [] : null;

                    this.inputChanged();
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },

        convertToBase64File(selectedFile) {
            return new Promise(function (resolve, reject) {
                let reader = new FileReader();
                let convertedFile = '';

                reader.readAsDataURL(selectedFile);

                reader.onload = function () {
                    convertedFile = reader.result;
                };

                reader.onerror = function (error) {
                    reject(error);
                };

                reader.onloadend = function () {
                    resolve(convertedFile);
                };
            });
        },

        cancelFileUpload(error) {
            this.applicationDocument = this.currentDocuments.length > 0 ? [] : null;
        },

        checkFileExtention(fileName) {
            var re = /(?:\.([^.]+))?$/;
            var ext = re.exec(fileName)[1];

            return this.component.allowed_file_types.includes(ext) ? true : false;
        },
    },

    mounted() {
        if (this.component.component_default_value) {
            this.currentDocuments = this.component.component_default_value;
            this.applicationDocument = this.currentDocuments.length > 0 ? [] : null;
        }

        if (this.validations) {
            // 30KB || (30 * 1024 bytes)
            this.validations.push((value) =>
                Array.isArray(value)
                    ? value.length < 1
                    : !value ||
                      value.size < this.calculateSizeInKB() ||
                      //   value.size < 5 * 1024 * 1024 ||
                      `${this.component.component_label} size should be less than  ` +
                          this.component.file_size +
                          this.component.file_size_unit.toUpperCase()
            );
        }
    },
};
</script>
