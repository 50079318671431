import { HTTP } from './http-common';
import store from '../store';

class AuthService {
    login(user) {
        return HTTP.post('login', user).then((response) => {
            if (response.data.token) {
                localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('utmData', JSON.stringify(store.state.auth.utmData));
                localStorage.setItem('token', response.data.token);
            }

            return response.data;
        });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }

    register(user) {
        return HTTP.post('register', user);
    }

    resetPassword(payload) {
        return HTTP.post('request-new-password', payload);
    }

    changePassword(payload) {
        return HTTP.post('change-password', payload);
    }

    changeEmail(payload) {
        return HTTP.post('change-email', payload);
    }

    changeUserPassword(payload) {
        return HTTP.post('change-password/user', payload);
    }

    changeUserEmail(payload) {
        return HTTP.post('change-email/user', payload);
    }
}

export default new AuthService();
